<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm title="Forgot Password"
                       in-progress-message="JDoodle is trying to send you password reset link, Please wait..."
                        success-message="Please check your mail for Password reset Link. (JDoodle sents mail only if the entered mail is already registered)."
                        action-text="Send Password Reset Link"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="recaptchaAction"
                       @close="$refs.sideBar.emitToggle()">
        <DoodleInput field-name="email" label-text="Email" type="email"
                     left-icon="address-card"
                     rule="required|email"
                     v-model="doodleForm.email" place-holder="Email"/>
        <div id="forgotPasswordContainer" class="g-recaptcha" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi"></div>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import DoodleInput from './Utils/DoodleInput'
import SideBar from './Utils/SideBar'

export default {
  name: 'forgotPassword',
  components: { SideBar, DoodleInput, DoodleGeneralForm },
  inject: ['$validator'],
  mixins: [formMixin, recaptchaMixin],
  data: function () {
    return {
      doodleForm: {
        email: null
      }
    }
  },
  mounted () {
    this.loadRecaptcha('forgotPasswordContainer', this.requestPasswordReset)
  },
  methods: {
    requestPasswordReset (gc) {
      this.executeAPI({
        url: '/api/register/forgotPassword',
        data: { 'email': this.doodleForm.email, gc: gc },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'forgot-password',
        jdaLabel: ''
      })
    }
  }
}
</script>
